import constate from "constate";
import { useState } from "react";

interface AppState {
  isAppActive: boolean;
}

function useAppState() {
  const [state, setState] = useState<AppState>({
    isAppActive: true,
  });

  function setIsAppActive(isActive: boolean) {
    setState({
      isAppActive: isActive,
    });
  }

  return {
    state,
    setIsAppActive,
  };
}

export const [AppStateProvider, useAppStateContext] = constate(useAppState);
