export const RootPaths = {
  NO_USER_PAGE: "/noUserDetails",
  HOME: "/home",
  WELCOME: "/welcome",
  LINK: "/link",
  APP_V2: "/v2",
  APP_V2_HOME: "/home-v2",
};

/**
 * Split these up and move these paths into the projects that manage these paths.
 */
export const DeprecatedGlobalAppV1Paths = {
  MY_SHIFTS: `${RootPaths.HOME}/myShifts`,
  OPEN_SHIFTS: `${RootPaths.HOME}/openShifts`,
  URGENT_SHIFTS: `${RootPaths.HOME}/urgentShifts`,
  RATINGS: `${RootPaths.HOME}/ratings`,
  RATINGS_ATTENDANCE_SCORE: `${RootPaths.HOME}/ratings/attendance-score`,
  RATINGS_PRIORITY_ACCESS: `${RootPaths.HOME}/ratings/priority-access`,
  RATINGS_A_TEAM: `${RootPaths.HOME}/ratings/a-team`,
  OPEN_SHIFT_DAY_VIEW: `${RootPaths.HOME}/openShifts/:date`,
  ACCOUNT: `${RootPaths.HOME}/account`,
  CHAT: `${RootPaths.HOME}/account/chat/:facilityId`,
  CHAT_CHANNELS_LIST: `${RootPaths.HOME}/account/chat`,
  SUPPORT: `${RootPaths.HOME}/account/support`,
  ADDRESS: `${RootPaths.HOME}/account/address`,
  ADDRESS_SEARCH: `${RootPaths.HOME}/account/searchAddressLocation`,
  ADDRESS_EDIT: `${RootPaths.HOME}/account/editAddress`,
  PAYROLL: `${RootPaths.HOME}/account/payroll`,
  // @deprecated, use `AppV2AccountRoutes.DOCUMENTS` instead.
  DEPRECATED_V1_DOCUMENTS: `${RootPaths.HOME}/account/documents`,
  // @deprecated, use `AppV2AccountRoutes.DOCUMENTS/:hcfId/hcfName` instead.
  DEPRECATED_V1_DOCUMENTS_HCF: `${RootPaths.HOME}/account/documents/:hcfId/:hcfName`,
  DOCUMENT_VIEW: `${RootPaths.HOME}/account/documentView`,
  PROFILE: `${RootPaths.HOME}/account/profile`,
  PROFILE_CONTRACT: `${RootPaths.HOME}/account/profile/contract`,
  REFERRAL: `${RootPaths.HOME}/account/referral`,
  CO_WORKER_REFERRAL: `${RootPaths.HOME}/account/referral/co-worker`,
  REFERRAL_V2: `${RootPaths.HOME}/account/worker-referrals`,
  MY_SHIFT_DAY: `${RootPaths.HOME}/myShiftList/:shiftDate`,
  MY_SHIFT_DETAIL: `${RootPaths.HOME}/myShifts/:shiftId`,
  UNVERIFIED_SHIFTS: `${RootPaths.HOME}/myShifts/unverifiedShifts`,
  PENDING_SENT_HOME_REQUESTS: `${RootPaths.HOME}/myShifts/pendingSentHomeRequests`,
  SUCCESS_ONBOARDING: `${RootPaths.HOME}/alreadyOnboarded`,
  PAYMENT_SERVICE_ONBOARDING: `${RootPaths.HOME}/onboardingPayments`,
  UPDATED_CONTRACTOR_AGREEMENT: `${RootPaths.HOME}/updatedContractorAgreement`,
  FACILITY_DETAIL: `${RootPaths.HOME}/facility/:facilityId`,
  OPEN_SHIFT_FACILITY: `${RootPaths.HOME}/openShifts/:facilityId/shifts`,
  NEED_HELP: `${RootPaths.HOME}/account/needhelp`,
  HELP_DETAILS: `${RootPaths.HOME}/account/needhelp/details`,
  UPDATE_PROFILE: `${RootPaths.HOME}/updateProfile`,
  DELETE_DATA: `${RootPaths.HOME}/deleteData`,
  LICENSE_MANAGER: `${RootPaths.HOME}/account/licenses`,
  LICENSE_DETAILS: `${RootPaths.HOME}/account/licenses/:licenseId/details`,
  ADD_LICENSE: `${RootPaths.HOME}/account/licenses/add`,
  COLLEAGUE_LIST: `${RootPaths.HOME}/colleagueList`,
  RATE_NEGOTIATIONS: `${RootPaths.HOME}/rate-negotiations`,
  FRIENDS_LIST: `${RootPaths.HOME}/account/friends`,
  SETUP_STRIPE: `${RootPaths.HOME}/stripeSetUp`,
  PROFESSIONAL_REFERENCE: `${RootPaths.HOME}/professional-references`,
  NOTIFICATION_CENTER: `${RootPaths.HOME}/notification-center`,
  CAREER_AGENT: `${RootPaths.HOME}/careerAgent`,
  WORKPLACE_REFERRALS: `${RootPaths.HOME}/account/workplace-referrals`,
};
